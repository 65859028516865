.popup {
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    display: flex;
}

.popup-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    //width: 50%;
    min-width: 10%;
    max-width: 50%;
    border-radius: 15px;
}

.popup-content .popup-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.popup-content img {
    max-width: 100%;
}

@media (max-width: 1024px) {
    .popup-content {
        //width: 60%;
        min-width: 15%;
        max-width: 70%;
    }
}

@media (max-width: 700px) {
    .popup-content {
        //width: 70%;
        min-width: 20%;
        max-width: 80%;
    }
}